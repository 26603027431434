<template>
  <button v-if="showButAdd" @click="editBill('addSub')" type="button" class="btn btn-success p-1" :title="$t('bill.billactions.addChildAccount')">
    <div class="icon-size"><font-awesome-icon icon="plus" /></div>
  </button>
  <button v-if="showButEdit && currentUser.bill_id!=selBill.id" @click="editBill('edit')" type="button" class="btn btn-primary p-1" :title="$t('bill.billactions.changeAccount')">
    <div class="icon-size"><font-awesome-icon icon="pencil-alt" /></div>
  </button>
  <button v-if="showButBlock && currentUser.bill_id!=selBill.id" @click="rowSetActiveStatus(selBill.id, 'blocked')" type="button" class="btn btn-secondary p-1" :title="$t('bill.billactions.block')">
    <div class="icon-size"><font-awesome-icon icon="unlock-alt" /></div>
  </button>
  <button v-if="showButUnblock && currentUser.bill_id!=selBill.id" @click="editBill('unblock')" type="button" class="btn btn-info p-1" :title="$t('bill.billactions.unlock')">
    <div class="icon-size"><font-awesome-icon icon="lock" /></div>
  </button>
  <button v-if="showButDel && currentUser.bill_id!=selBill.id" @click="delBill()" type="button" class="btn btn-danger p-1 delete-button" :title="$t('bill.billactions.deleteAccount')">
    <div class="icon-size"><font-awesome-icon icon="trash-alt" /></div>
  </button>
</template>

<script setup>
import {inject, computed, defineProps, defineEmits, provide, ref} from "vue";
import BillService from "../../services/bill.service";

const $t = inject('$t')

const currentUser = inject('currentUser')

const props = defineProps({
  selBill:  Object
});

const emit = defineEmits(['selBill', 'updBills'])

const content = ''

const access_groups = inject('access_groups')
const set_result = inject('set_result')
const rowSetActiveStatus = inject('rowSetActiveStatus')
const calculateChildCount = inject('calculateChildCount')

const showButAdd = computed(() => access_groups.value['bill_sub_add'] && props.selBill.status=='active')
const showButEdit = computed(() => props.selBill.status=='active')
const showButUnblock = computed(() => access_groups.value['bill_sub_block'] && (props.selBill.status=='blocked'))
const showButBlock = computed(() => access_groups.value['bill_sub_block'] && props.selBill.status=='active')
const showButDel = computed(() => access_groups.value['bill_sub_del'] && (props.selBill.status=='active' || props.selBill.status=='blocked'))

function verifyBlock (id)
{
  BillService.verifyBlock(id).then(
    () => {
      emit('selBill', {is_sel: true, sel_bill: props.selBill, type: 'unblock'})
    },
    (error) => {
      let nam_err=''
      let err_data=error.response.data.err_info
      if(err_data.indexOf('###upstream_blocking###')!=-1) {
        //let result = err_data.match(/[#][#][#](.*)[#][#][#]/);
        nam_err=$t('bill.billactions.upstream_blocking')
      }

      set_result(error.response.status, error, nam_err)
    }
  )
}

function editBill(type)
{
  if(type=='unblock') {
    verifyBlock(props.selBill.id)
    return
  }

  emit('selBill', {is_sel: true, sel_bill: props.selBill, type: type})
}

function updBills()
{
  emit('updBills')
}

function getRandomNumber(min, max)
{
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function delBill()
{
  const num1 = getRandomNumber(1, 10)
  const num2 = getRandomNumber(1, 10)
  const operator = Math.random() < 0.5 ? "+" : "-"

  const billCount = 1 + props.selBill.childCount

  const confirmation = prompt($t('bill.billactions.confirmtext')+billCount+'. '+$t('bill.billactions.confirmtext1')+num1+' '+operator+' '+num2+' = ')
  const expectedAnswer = operator === "+" ? num1 + num2 : num1 - num2

  if (confirmation !== null) {
    const expectedAnswer = operator === "+" ? num1 + num2 : num1 - num2;

    if (parseInt(confirmation) === expectedAnswer) {
      rowSetActiveStatus(props.selBill.id, 'deleted')
      alert($t('bill.billactions.delcnt') + ' ' + parseInt(billCount))
    } else {
      alert($t('bill.billactions.answdel'))
    }
  }
}
</script>

<style scoped>
  button {
    margin: 3px;
  }
  .delete-button {
    margin-left: 0.5rem;
  }
</style>
