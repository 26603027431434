<template>
  <div class="container-fluid">
    <p></p>
    <div v-if="!is_bill" class="row">
      <div class="col">
      </div>
      <div class="col">
        <p>{{ $t('bill.bills.showBlockedAccounts') }}<input v-model="showBlockBill" class="m-1" type="checkbox"><br></p>
      </div>
      <div class="col">
        <p>{{ $t('bill.bills.showDeletedAccounts') }}<input v-model="showDelBill" class="m-1" type="checkbox"><br></p>
      </div>
    </div>
    <vue-good-table v-if="!is_bill"
        styleClass="vgt-table nowrap row_color_hover"
        :columns="columns"
        :rows="rows"
        v-on:column-filter="onColumnFilter"
        :pagination-options="{
          enabled: true,
          perPage: 100,
          perPageDropdown: perPageDropdown,
          perPageDropdown: perPageDropdown,
          nextLabel: $t('app.vue-good-table-next.nextLabel'),
          prevLabel: $t('app.vue-good-table-next.prevLabel'),
          rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
          ofLabel: $t('app.vue-good-table-next.ofLabel'),
          pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
          allLabel: $t('app.vue-good-table-next.allLabel')
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'id', type: 'asc'}
        }"
        :row-style-class="rowStyleClassFn"
        compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
      <template #table-row="props">
        <bill-actions v-if="props.column.field == 'actions'" @selBill="onSelBill" @updBills="updBills" :selBill="props.row"></bill-actions>
      </template>
    </vue-good-table>
    <div v-if="is_bill">
      <bill v-if="type!='unblock'" @selRow="onSelBill" @onGetRows="updBills" :selRow="sel_bill" :type="type"></bill>
      <BillBlock v-if="type=='unblock'" @selRow="onSelBill" :selRow="sel_bill"></BillBlock>
    </div>
  </div>
</template>

<script setup>
import BillService from "../../services/bill.service";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import BillActions from "./BillActions.vue"
import Bill from "./Bill.vue";
import BillBlock from "./BillBlock.vue";
import {inject, ref, onMounted, watch, computed, provide, reactive} from "vue";
import {useStore} from "vuex";
import BillManagerBalans from "../BillManager/BillManagerBalans";

const sortByInt = inject('sortByInt')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const type = ref('')

const store = useStore()

const set_result = inject('set_result')

const currentUser = inject('currentUser')

const showDelBill = ref(false)
const showBlockBill = ref(true)

const content = ref("")
const is_bill = ref(false)
const sel_bill = ref({})

const filters=ref({id: '', bill_name: '', parent_title: '', bill_comment: '', currency: '', balance: ''})
function onColumnFilter(params)
{
  filters.value=params.columnFilters
}

const selBillsInfoAll = inject('selBillsInfoAll')

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
        {
          label: $t('bill.bills.number'),
          field: 'id',
          filterOptions: {enabled: true, filterFn: equals_cf, placeholder: $t('bill.bills.number'), filterValue: filters.value.id},
          width: '100px',
          tdClass: 'table_text_color',
          sortFn: sortByInt,
        },
        {
          label: $t('bill.bills.name'),
          field: 'bill_name',
          filterOptions: {enabled: true, placeholder: $t('bill.bills.name'), filterValue: filters.value.bill_name},
          tdClass: 'table_text_color'
        },
        {
          label: $t('bill.bills.parentAccount'),
          field: 'parent_title',
          filterOptions: {enabled: true, placeholder: $t('bill.bills.parentAccount'), filterValue: filters.value.parent_title, filterFn: filterParent},
          tdClass: 'table_text_color'
        },
        {
          label: $t('bill.bills.note'),
          field: 'bill_comment',
          filterOptions: {enabled: true, placeholder: $t('bill.bills.note'), filterValue: filters.value.bill_comment},
          tdClass: 'table_text_color'
        },
        {
          label: $t('bill.bills.currency'),
          field: 'currency',
          filterOptions: {enabled: true, placeholder: $t('bill.bills.currency'), filterValue: filters.value.currency},
          width: '100px',
          tdClass: 'table_text_color'
        },
        {
          label: $t('bill.bills.balance'),
          field: 'balance',
          filterOptions: {enabled: true, filterFn: numbermore_cf, placeholder: $t('bill.bills.balance'), filterValue: filters.value.balance},
          width: '100px',
          tdClass: 'table_text_color'
        },
        {
          label: $t('bill.bills.action'),
          field: 'actions',
          html: true,
          tdClass: 'table_text_color m-0 p-0',
          width: '150px',
        },
      ])

const rows = ref([])

function filterParent(data, filter)
{
  let res=false

  if(data!=null) {
    var reg_num=/^[0-9]*$/
    let is_num=filter.match(reg_num)

    if(is_num==null) {
      if (data.toLowerCase().indexOf(filter.toLowerCase())!=-1) {
        res=true
      }
    } else {
      var get_bill_id=/[(].*[)]$/
      let bill_id=data.match(get_bill_id)
      if (bill_id=='('+filter+')') {
        res=true
      }
    }
  }

  return res
}

function rowStyleClassFn(row)
{
  let color_class=''

  if(row.status=='blocked') {
    color_class+=' blocked_bill'
  }

  if(row.status=='deleted') {
    color_class+=' deleted_bill'
  }

  return color_class
}

function onSelBill(params)
{
  sel_bill.value=params.sel_bill
  is_bill.value=params.is_sel
  type.value=params.type
}

function getBillsTree ()
{
  store.dispatch('bill/updBills')
}

function updBills()
{
  getBills()
  getBillsTree()
}
provide('updBills', updBills)

function changeShowBill()
{
  getBills()
}
watch(showDelBill, changeShowBill)
watch(showBlockBill, changeShowBill)

function getBills()
{
  BillService.getBillsAll(showBlockBill.value, showDelBill.value).then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        selBillsInfoAll.value = response.data

        let rwn = []
        for (let key in response.data) {
          let cur = response.data[key]

          if (cur.id == currentUser.value.bill_id) {
            cur.bill_comment = ''
          }

          cur.childCount = calculateChildCount(cur.id, response.data)

          rwn.push(cur)
        }

        rows.value = rwn;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function calculateChildCount(parentId, billData)
{
  let childCount = 0;
  for (let key in billData) {
    let cur = billData[key];
    if (cur.parent_id === parentId) {
      childCount++;
    }
  }
  return childCount;
}
provide('calculateChildCount', calculateChildCount)

function rowSetActiveStatus(id, status)
{
  BillService.setActiveStatus(id, status).then(
      () => {
        updBills()
      },
      (error) => {
        let nam_err=''
        let err_data=error.response.data.err_info
        if(err_data.indexOf('###upstream_blocking###')!=-1) {
          //let result = err_data.match(/[#][#][#](.*)[#][#][#]/);
          nam_err=$t('bill.billactions.upstream_blocking')
        }

        set_result(error.response.status, error, nam_err)
      }
  )
}
provide('rowSetActiveStatus', rowSetActiveStatus)

function equals_cf(data, filterString)
{
  var x = parseInt(filterString)
  return data == x;
}

function numbermore_cf(data, filterString)
{
  var x = parseInt(filterString)
  return data >= x;
}

onMounted(() =>
{
  getBills()
  calculateChildCount()
})
</script>
<style>
  .table_text_color {
    color: #111111 !important;
  }

  .blocked_bill {
    background-color: #c7ced8;
    font-style: italic;
  }

  .deleted_bill {
    background-color: #f97272;
  }
</style>
